module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-JCSDVS9H18"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Raghav HV | Music Composer","short_name":"RaghavHV","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/images/assets/raghav-hv-rhv-logo-favicon.png","description":"Raghav HV is a versatile music composer specializing in film scores, advertisements, and songs.","lang":"en","cache_busting_mode":"name","theme_color_in_head":true,"legacy":true,"shortcuts":[{"name":"Home","short_name":"Home","description":"Homepage","url":"/","icons":[{"src":"src/images/assets/raghav-hv-rhv-logo-favicon.png","sizes":"33x33"}]}],"icons":[{"src":"src/images/assets/raghav-hv-rhv-logo-favicon.png","sizes":"33x33","type":"image/png"}],"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9de554f72e9cd8dd60add60b8d378289"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
